<template lang="pug">
  include /mixins
  SecurityAccordion
    template(v-slot:caption='')
      | {{ _("p Localization:") }}
    template(v-slot:content='')
      card-panel(
        offset='vertical'
      )
        row(offset)
          cell(
            v-for='(item, index) in providers'
            :key="`${index}_${item.id || item.model}`"
            cols='12'
          )
            security-localization-provider-item(
              :initial-provider='item'
              :key="`${index}_${item.id || item.model}`"
              @legal='setLegal'
              @finish='fetchProviderData'
            )
          cell(cols='12 6-sm 2-md 6-lg 2-2xl')
            row(offset)
              cell(cols='12')
                v-button(
                  variant='success'
                  border='whole'
                  type='button'
                  @click='addProvider'
                )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        size='6'
                        :variant='["bold", "uppercase"]'
                      ) {{ _('Add') }}
            
</template>

<script>
/* eslint-disable no-unused-vars */
import { 
  ref, 
  onMounted, 
  computed
} from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import { fileLoader } from '@cabinet/util'
import PSS from '@cabinet/services/api/provider-settings'

import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import SecurityLocalizationProviderItem from '@cabinet/components/settings/SecurityLocalizationProviderItem'

import isNil from 'ramda/src/isNil'

export default {
  components: {
    SecurityAccordion,
    SecurityLocalizationProviderItem,
  },
  setup(props, context) {

    const providers = ref([])

    const fetchProviderData = async () => {
      try {
        const res = await useResponse(PSS.fetchProvidersData(), context.root, context.refs.observer)
        providers.value = res.data
        // if (res.data) {
        //   console.log(res.data)
        //   logo.value = res.data.logo
        //   if (!("link" in res.data) || res.data.slug === "" || res.data.link === "") {
        //     context.emit('legal', false)
        //   } else {
        //     context.emit('legal', true)
        //   }
        // } else {
        //   context.emit('legal', false)
        // }
      } catch (error) {
        console.error(error)
      }
    }

    function setLegal(value) {
      context.emit('legal', value)
    }
    function addProvider() {
      providers.value.push({
        modelId: Date.now(),
      })
    }

    fetchProviderData()

    return {
      providers,
      setLegal,
      addProvider,
      fetchProviderData,
    }
  }
}
</script>