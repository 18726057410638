<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close(@click='$emit("close")')
      icon(type='close')
    loading-wrapper(:loading='loading')
      validation-observer(
        ref='observer'
        v-slot='{invalid}'
        tag='form'
        @submit.prevent='save'
        autocomplete='off'
      ).form
        card
          card-panel(offset='full')
            row(offset)
              cell(cols='12')
                row(offset)
                  cell(cols='12')
                    v-caption(size='3') {{ _('Add tour')}}
                  cell(cols='12')
                    ui-input(:placeholder="_('Tour name')"
                              type='search'
                              name='title'
                              v-model='formData.title'
                              border='whole'
                              size='lg'
                              bg='relief-3'
                              validate='required')
                  cell(cols='12')
                    ui-input(
                      :placeholder="_('Presents *')"
                      :val='formData.presents'
                      type='search'
                      name='presents'
                      validate='required'
                      v-model='formData.presents'
                      size='lg'
                      border='whole'
                      bg='relief-3'
                    )
                  cell(cols='12')
                    v-select(v-model.number='formData.default_provider_settings'
                              :val='formData.default_provider_settings'
                              :options='providers'
                              :label-by="'slug'"
                              :track-by="'id'"
                              :placeholder="_('Provider')"
                              validate='required'
                              size='lg'
                              absolute
                              bg='relief-3'
                              :name='"default_provider_settings"')
                      template(v-slot:single-label="{ option }")
                        span {{ option.slug }} - {{ option.link }}

                      template(v-slot:option="{option}")
                        .option__desc(:title='option.tooltip')
                          span.option__title {{ option.slug }} - {{ option.link }}
                  cell(cols='12')
                    .form__image-wrapper
                      row.form__image-wrapper-label(
                        justification='center'
                        v-if='!formData.preview'
                      )
                        cell(cols='10')
                          row(offset)
                            cell(cols='12')
                              v-caption(
                                size='5'
                                :variant='["center", "secondary"]'
                                color='brand-4'
                              ) Size: 1020*980 px
                            cell(cols='12')
                              v-button(
                                variant='danger'
                                border='whole'
                                tag='label'
                                for='file'
                              )
                                template(v-slot:prepend='')
                                  cell
                                    v-button-element(
                                      size='lg'
                                      variant="warning-lite"
                                    )
                                      v-caption(variant='bold')
                                        icon(type='upload'
                                            size='md')
                                cell(cols='auto')
                                  v-button-element(size='lg')
                                    v-caption(
                                      size='6'
                                      :variant='["bold", "uppercase"]'
                                    ) {{ _('Upload Poster(T)') }}
                        input(
                          type='file'
                          style='display:none'
                          id='file'
                          accept='image/x-png,image/gif,image/jpeg'
                          ref='image'
                          @change='getFile($event.target.files);'
                        )
                      img(:src='formData.preview || "/static/images/poster-stub-2.png"')

              cell(cols='12' v-if='fileName')
                v-button(
                  variant='clear'
                  hover='danger'
                  @click.prevent='removeImage'
                  alignment='center'
                )
                  cell(cols='10')
                    v-button-element
                      v-caption(
                        size='5'
                        color='default' variant='break-all') {{ fileName }}
                  template(v-slot:append='')
                    cell(cols='2')
                      v-button-element( )
                        icon(
                          type='close'
                          color='warning'
                          size='xs'
                        )

              cell(cols='12')
                row(offset)


                  cell(cols='6')
                    v-select(v-model.number='formData.type'
                              :val='formData.type'
                              :options='eventTypes'
                              :placeholder="_('Type')"
                              validate='required'
                              size='lg'
                              absolute
                              bg='relief-3'
                              :name='"type"')
                  cell(cols='6')
                    v-select(v-model.number='formData.status'
                              :val='formData.status'
                              :options='superChargedStatuses'
                              :placeholder="_('Status')"
                              validate='required'
                              size='lg'
                              absolute
                              bg='relief-3'
                              :name='"status"')
                  cell(cols='6')
                    +b.control-box
                      v-button(
                        border='whole'
                        @click='$emit("close")'
                      )
                        cell(justification='center').cols
                          v-button-element(size='lg')
                            v-caption(
                              size='5'
                              :variant='["bold", "uppercase"]'
                            ) {{ _('cancel(T)') }}
                  cell(cols='6')
                    +b.control-box
                      v-button(
                        type='submit'
                        variant='success'
                        border='whole'
                      )
                        cell(justification='center').cols
                          v-button-element(size='lg')
                            v-caption(
                              size='5'
                              :variant='["bold", "uppercase"]'
                            ) {{ _('save(T)') }}


</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Preloader from '@cabinet/components/vendor/Preloader'
import EventsService from '@cabinet/services/api/events'
import { useResponse } from '@cabinet/composables/use-response'
import { EventBus } from '@cabinet/components/eventbus/eventbus'
import PSS from '@cabinet/services/api/provider-settings'
// const toDataURL = url =>
//   fetch(url)
//     .then(response => response.blob())
//     .then(
//       blob =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader()
//           reader.onloadend = () => resolve(reader.result)
//           reader.onerror = reject
//           reader.readAsDataURL(blob)
//         })
//     )
export default {
  components: {
    Preloader
  },
  props: {
    id: {
      required: false
    },
    event: {
      required: false
    },
    callback: {
      required: false
    }
  },
  data() {
    return {
      preview: null,
      loading: false,
      fileName: '',
      providers: [],
      formData: {
        status: '',
        presents: '',
        type: '',
        title: '',
        preview: null,
        default_provider_settings: null,
      },
    }
  },
  computed: {
    ...mapGetters('eventsModule', [
      'eventStatuses',
      'eventTypes'
    ]),
    superChargedStatuses() {
      return this.eventStatuses.map(s => ({
        ...s,
        $isDisabled: (this.event && !this.event.shows.length) || (s.id && !this.id),
        tooltip: s.id && !this.id ? this._('This option is disabled.\nTour has no shows.') : ''
      }))
    }

  },

  methods: {
    ...mapActions('eventsModule', {
      fetchEvent: 'fetchEvent',
      updateEvent: 'updateEvent',
      getEventStatuses: 'getEventStatuses'
    }),
    ...mapActions('vendorsModule', ['fetchVendors']),

    async getProviders() {
      const res = await PSS.fetchProvidersData()
      this.providers = res.data.data
    },
    getFile(files) {
      for (var i = 0; i < files.length; i++) {
        var file = files[i]
        var reader = new FileReader()

        this.fileName = file.name
        reader.onload = e => {
          // setTimeout(() => {
          this.$set(this.formData, 'preview', e.target.result)
        }
        reader.readAsDataURL(file)
      }
    },
    transform(data) {
      let copy = {...data}
      Object.keys(copy).forEach(key => {

        if (copy[key] && copy[key].id > -1) {
          copy[key] = copy[key].id
        }
        if (this.fileName === copy.preview) {
          delete copy.preview
        }
      })
      return copy
    },
    async save() {
      // this.loading.state = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        let data = this.transform(this.formData)
        this.loading = true
        try {
          if (this.id) {
            let obj = {
              event: data,
              id: this.id,
            }
            await useResponse(this.updateEvent(obj), this, this.$refs.observer)
            this.$emit('close')

          } else {
            await useResponse(EventsService.createEvent(data), this, this.$refs.observer)
            this.$emit('close')
          }
          if (!this.$route.params.id) {
            this.$emit('close')
            this.callback()
            // setTimeout(() => {
            // setTimeout(() => {
            //   this.$router.push('/account/events/')
            // }, 1000)
          } else {
            EventBus.$emit('updated')
          }
          this.loading = false

        } catch (error) {
          this.loading = false
        }
      }
    },
    clearImage() {
      this.formData.preview = ''
      this.fileName = null
    },
    removeImage() {
      if (this.formData.id) {
        useResponse(EventsService.deleteEventImage(this.formData.id), this, this.$refs.observer).then(this.clearImage)
      } else {
        this.clearImage()
      }
    },

  },
  async created() {
    this. getProviders()
    await this.getEventStatuses()
    if (this.id && this.event) {
      this.loading = true
      // let { preview, ...rest } =
      this.formData = this.event
      if (!this.formData.is_owner) {
        this.$router.push('/account/account-settings/')
      }
      let { status, type } = this.formData
      this.formData.status = typeof status === 'string' ? this.eventStatuses.find(s => s.title === status) : status


      this.formData.type = typeof type === 'string' ? this.eventTypes.find(s => s.id === type) : type

      this.fileName = this.formData.preview
      this.loading = false
      // //(this.formData.tags)
    } else {
      this.formData.status = this.eventStatuses[0]
    }
  },
}
</script>
