
<template lang='pug'>
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  )

    row(offset)
      cell(cols='12')
        validation(name='non field errors')
      cell(cols='12 6-sm 8-md')

        ui-input(
          :placeholder="_('Show name*')"
          :val='form.title'
          type='search'
          name='title'
          validate='required'
          v-model='form.title'
          size='lg'
          border='whole'
          bg='relief-3'
        )
      cell(alignment='bottom' cols='12 6-sm 4-md')
        v-select(
          v-model='form.status'
          size='lg'
          border='whole'
          bg='relief-3'
          :val='form.status'
          :full='true'
          :placeholder="_('Status*')"
          :options='statuses'
          :name='"status"'
          validate='required'
        )

      cell(cols='12 8-md')
        row(offset)
          +b.cols--12.cell
            v-caption(size='6' color='brand-1' variant='bold') {{ _('Переключение формата времени') }}
          +b.cols.cell
            ui-toggler(
              type='checkbox'
              v-model='form.is_locale'
              size='lg'
              border='whole'
              bg='relief-3'
              :val='form.is_locale'
              @input='changeLocale'
              name='locale'
            )
              template(v-slot:prepend='')
                v-caption(size='6' color='brand-1' variant='bold') {{ _('s US') }}
              template(v-slot:append='')
                v-caption(size='6' color='brand-1' variant='bold') {{ _('s EU') }}

          cell(cols='12')
            card-panel(offset='vertical')
              card-panel(offset='vertical_3xs')
                row(offset)

                  cell(cols='12 4-md')
                    transition(name='fade')
                      ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                        flat-pickr(
                          v-model='form.time_at_display'
                          :config='timePicker'
                          @on-close='changeTime($event, "time_at_display")'
                          name='time'
                          :placeholder="_('Time')"
                        ).flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                        transition(name='fade')
                          .form__error(v-if='errors[0]') {{ errors[0] }}
                  cell(cols='12 4-md')
                    ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                      flat-pickr(
                        v-model='form.date_at_display'
                        :config='config'
                        name='date'
                        validate='required'
                        :placeholder="_('Date*')"
                      ).flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                      transition(name='fade')
                        .form__error(v-if='errors[0]') {{ errors[0] }}
                  cell(cols='12 4-md')
                      ui-input(
                        :placeholder="_('Duration')"
                        :val='form.duration'
                        type='search'
                        size='lg'
                        border='whole'
                        bg='relief-3'
                        name='duration'
                        v-model='form.duration'
                      ).form__control--styled

              card-panel(offset='vertical_3xs')
                row(offset alignment='center')
                  cell(cols='12 4-md')
                    v-caption(size='6' variant='bold') {{ _('Start time and date:')}}

                  cell(cols='12 4-md')
                    ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                      transition(name='fade')
                        flat-pickr(
                          v-model='form.start_at_time'
                          :config='timePicker'
                          name='start_at_time'
                          @on-close='changeTime($event, "start_at_time")'
                          :placeholder="_('hh:mm*')"
                        ).flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                      transition(name='fade')
                        .form__error(v-if='errors[0]') {{ errors[0] }}
                  cell(cols='12 4-md')
                    ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                      flat-pickr(
                        v-model='form.start_at_date'
                        :config='config'
                        name='start_at_date'
                        validate='required'
                        :placeholder="_('mm/dd/yy*')"
                      ).flatpickr-input.flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                      transition(name='fade')
                        .form__error(v-if='errors[0]') {{ errors[0] }}
                  cell(cols='12' v-if='$refs.observer && $refs.observer.errors && $refs.observer.errors["start at"] && $refs.observer.errors["start at"].length')
                    row(justification='end')
                      cell(cols='narrow')
                        validation(name='start at')
              card-panel(offset='vertical_3xs')
                row(offset alignment='center')
                  cell(cols='12 4-md')
                    v-caption(size='6' variant='bold') {{ _('Finish time and date:')}}
                  cell(cols='12 4-md')
                    ValidationProvider.control-input(name='finish at time' :rules='{required: true }' v-slot='{errors}')
                      transition(name='fade')
                        flat-pickr(
                          v-model='form.finish_at_time'
                          :config='timePicker'
                          @on-close='changeTime($event, "finish_at_time")'
                          name='finish_at_time'
                          :placeholder="_('hh:mm')"
                        ).flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                      transition(name='fade')
                        .form__error(v-if='errors[0]') {{ errors[0] }}
                      //- transition(name='fade')
                      //-   .form__error(v-if='!dateValid') {{ _('dates are equal') }}



                  cell(cols='12 4-md')
                    ValidationProvider.control-input(name='finish at date' :rules='{ required: true }' v-slot='{errors}')
                      flat-pickr(
                        v-model='form.finish_at_date'
                        :config='config'
                        name='finish_at_date'
                        :placeholder="_('mm/dd/yy')"
                      ).flatpickr-input.flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                      transition(name='fade')
                        .form__error(v-if='errors[0]') {{ errors[0] }}
                  cell(cols='12' v-if='$refs.observer && $refs.observer.errors && $refs.observer.errors["finish at"] && $refs.observer.errors["finish at"].length')
                    row(justification='end')
                      cell(cols='narrow')
                        validation(name='finish at')

                  //- cell(cols='narrow' justification='right')

              card-panel(offset='vertical_3xs')
                row(offset alignment='center')
                  cell(cols='12 4-md')
                    v-caption(size='6' variant='bold') {{ _('Timezone:')}}
                  cell(cols='12 8-md')
                    v-select(
                      v-model='form.tz_id'
                      size='lg'
                      border='whole'
                      bg='relief-3'
                      :val='form.tz_id'
                      :full='true'
                      :options='timezones'
                      validate='required'
                      searchable
                      :name='"tz"'
                    )
              card-panel(offset='vertical_3xs')
                row(offset alignment='center')
                  cell(cols='12 4-md')
                    v-caption(size='6' variant='bold') {{ _('Age limit:')}}
                  cell(cols='12 8-md')
                    ui-input(
                      :placeholder="_('Age limit')"
                      :val='form.age_limit'
                      type='number'
                      size='lg'
                      border='whole'
                      bg='relief-3'
                      name='age_limit'
                      v-model='form.age_limit'
                    ).form__control--styled
      cell(cols='12 4-md')
        row(offset)
          cell(cols='12 6-sm')
            v-select(
              v-model='helpers.country'
              size='lg'
              border='whole'
              bg='relief-3'
              :val='helpers.country'
              :full='true'
              :placeholder="_('Country*')"
              :options='countries'
              validate='required'
              searchable
              :disabled='!!form.id'
              @input='fetchStates(helpers.country.id);resetFields("subdivision", "city", "place")'
              name='country'
            )
          cell(cols='12 6-sm')
            v-select(
              v-model='helpers.subdivision'
              size='lg'
              border='whole'
              bg='relief-3'
              :val='helpers.subdivision'
              :full='true'
              :placeholder="_('State*')"
              :options='states'
              validate='required'
              searchable
              @input='fetchCities(helpers.subdivision.id);resetFields("city", "place")'
              :disabled='!!form.id || !helpers.country'
              name='state'
            )
          cell(cols='12 6-sm')
            v-select(
              v-model='helpers.city'
              size='lg'
              border='whole'
              bg='relief-3'
              :val='helpers.city'
              :full='true'
              :placeholder="_('City*')"
              validate='required'
              :options='cities'
              searchable
              @input='fetchLocations_();resetFields("hall")'
              :disabled='!!form.id || !helpers.subdivision'
              name='city'
            )
          cell(cols='12 6-sm')
            v-select(
              v-model='form.hall'
              size='lg'
              border='whole'
              bg='relief-3'
              :val='form.hall'
              :full='true'
              :allow-empty='true'
              :placeholder="_('Hall*')"
              validate='required'
              searchable
              :options='locations || []'
              :disabled='!!form.id || !helpers.city'
              name='hall'
            )
          transition(name='fade')
            cell(cols='12' v-if='form.hall && !form.hall.has_schema')
              row(offset)
                cell(cols='12')
                  v-caption(size='6' variant='bold') {{ _('This hall has no schema')}}
                transition(name='fade')
                  cell(cols='12' v-if='form.hall_image')
                    img(:src='form.hall_image')
                cell(cols='12')
                  v-button(
                    variant='success'
                    border='whole'
                    @click='toggleCropper'
                    hover='success-lite'
                  )
                    template(v-slot:prepend='')
                      cell
                        v-button-element(
                          size='lg'
                          variant="success-lite"
                        )
                          v-caption(variant='bold')
                            icon(type='upload'
                                size='md')
                    cell(cols='auto')
                      v-button-element(size='lg')
                        v-caption(
                          size='5'
                          :variant='["bold", "uppercase"]'
                        ) {{ _('add schema') }}
        card(v-if='form.hall && !form.hall.has_schema')
          card-panel(offset='top_lg')
            wysiwyg(
                v-model="form.notice"
              )
      cell(cols='8')
        card-panel(offset='vertical')
          row(offset)
            cell(cols='12')
              card-panel(offset='vertical')
                row(alignment='center' space='xs')
                  cell(cols='narrow')
                    v-checkbox
                      +b.INPUT(
                        type='checkbox'
                        v-model='form.is_orphans'
                        :value='form.is_orphans'
                        name='is orghans'
                        :disabled="!isProTariff"
                      ).control-checkbox__input
                  cell(cols='narrow') {{ _('orphan seats') }}
            transition(name='fade')
              cell(cols='12')
                card-panel(offset='vertical')

                  row( offset)
                    cell(cols='12')
                      v-caption(
                        size='5'
                        tag='h5'
                      ) {{ _('Delivery method*')}}
                    cell(cols='12')
                      ValidationProvider(
                        name='shipment methods'
                        :rules='{required: {allowFalse: false}}'
                        v-slot='{errors}'
                      )
                        row(offset)
                          cell(
                            cols='narrow'
                            v-for='(method, index) in shipment_methods'
                            :key='index'
                          )
                            row(alignment='center' space='xs' offset)
                              cell(cols='narrow')
                                v-checkbox(
                                  name='shipment methods'
                                  :rules='{required: {allowFalse: false}}'
                                )
                                  template(v-slot='')
                                    +b.INPUT(
                                      type='checkbox'
                                      v-model='form.shipment_methods'
                                      size='lg'
                                      border='whole'
                                      bg='relief-3'
                                      :value='method.id'
                                      :name='method.title'
                                    ).control-checkbox__input
                              cell(cols='narrow')
                                v-caption(size='5') {{ method.title }}

                          cell(cols='12' v-if='errors[0]')
                            .form__error {{ errors[0]}}

            transition(name='fade')
              cell(cols='12')
                card-panel(offset='vertical')
                  row(alignment='center' offset)
                    cell(cols='12')
                      v-caption(
                        size='5'
                        tag='h5'
                      ) {{ _('Pay method*')}}
                    cell(cols='12')
                      ValidationProvider(
                        name='payment methods'
                        :rules='{required: {allowFalse: false}}'
                        v-slot='{errors}'
                      )
                        row(offset)
                          cell(
                            cols='narrow'
                            v-for='(method, index) in payment_methods'
                            :key='index'
                          )
                            row(alignment='center' space='xs' offset)
                              cell(cols='narrow')
                                v-checkbox()
                                  template(v-slot='')
                                    input(
                                      type='checkbox'
                                      v-model='form.payment_methods'
                                      size='lg'
                                      border='whole'
                                      bg='relief-3'
                                      :value='method.id'
                                    ).control-checkbox__input
                              cell(cols='narrow')
                                v-caption(size='5') {{ method.title }}
                          cell(cols='12' v-if='errors[0]')
                            .form__error {{ errors[0]}}
            transition(name='fade')
              cell(cols='12')
                card-panel(offset='vertical')
                  row(alignment='center' offset)
                    cell(cols='12')
                      v-caption(
                        size='5'
                        tag='h5'
                      ) {{ _('Currency*')}}
                    cell(cols='12')
                      ValidationProvider(
                        name='currency'
                        :rules='{required: {allowFalse: false}}'
                        v-slot='{errors}'
                      )
                        row(offset)
                          cell(
                            cols='6'
                          )
                            ui-currencies-list(
                              v-model='form.currency'
                              bg='relief-3'
                            )
                          cell(cols='12' v-if='errors[0]')
                            .form__error {{ errors[0]}}


            transition(name='fade')
              cell(cols='12')
                card-panel(offset='top')
                  row(alignment='center' offset)
                    cell(cols='narrow')
                      v-caption(
                        size='5'
                        tag='h5'
                      ) {{ _('Fees:')}}
                    cell(cols='narrow')
                      v-button(
                        @click='addFee'
                        :variant='["clear"]'
                        type='button'
                      )
                        template
                          icon(
                            type='add'
                            color='success'
                          )
                  row(
                    v-for='(fee, index) in feesData'
                    :key='index'
                    offset
                    alignment='center'
                  )
                    cell(cols='4')
                      ui-input(
                        :placeholder="_('Payment price '+(fee && fee.fee && fee.fee.type ? (fee.fee.type === 'percent' ? '%' : '$') : '')+'')"
                        :val='fee.price'
                        :negative='false'
                        type='search' v-digitsonly=''
                        name='fee descriptor price'
                        v-model='fee.price'
                        size='lg'
                        :disabled='!fee.fee'
                        border='whole'
                        bg='relief-3'
                        validate=''
                      )
                    cell(cols='4')
                      v-select(v-model='fee.fee'
                              size='lg'
                              border='whole'
                              bg='relief-3'
                              :val='fee.fee'
                              :full='true'
                              @input='fee.price = fee.fee.price'
                              :options='fees'
                              :placeholder="_('Fee name')"
                              name='fee descriptor fee')
                    cell(cols='auto' )
                      row(alignment='center' space='md')
                        cell(cols='narrow')
                          checkbox(
                            name='payment methods'
                          )
                            template(v-slot='')
                              +b.INPUT(
                                type='checkbox'
                                v-model='fee.is_show'
                                size='lg'
                                border='whole'
                                bg='relief-3'
                                :value='fee.id'
                                :name='fee.title'
                              ).control-checkbox__input
                            template(v-slot:text='')
                              v-caption(size='5') {{ _('show in hard') }}

                    cell(cols='narrow' )
                      transition(name='fade' mode='out-in')
                        v-button(
                          @click='deleteFee(index)'
                          :variant='["clear"]'
                          type='button'
                        )
                          template
                            icon(
                              type='close'
                              color='danger'
                            )
                  card(offset='top')
                    card-panel(offset='top')
                      ui-toggler(type='checkbox'
                                  :disabled="!isProTariff"
                                  v-model='form.is_tax'
                                  size='lg'
                                  border='whole'
                                  bg='relief-3'
                                  :val='form.is_tax'
                                  name='is left tickets'
                                  )
                        template(v-slot:append='')
                          row(alignment='center')
                            cell(cols='narrow')
                              v-caption(size='5') {{ _('Add additional taxes') }}
                            cell(cols='narrow')
                              v-tag(:type='form.is_tax ? "success" : "brand-6"') {{ form.is_tax ? _('Add taxes') : _('Disable taxes')}}
                  card(offset='top')
                    card-panel(offset='top')
                      ui-toggler(type='checkbox'
                                  v-model='form.is_left_tickets'
                                  size='lg'
                                  border='whole'
                                  bg='relief-3'
                                  :val='form.is_left_tickets'
                                  name='is left tickets')
                        template(v-slot:append='')
                          row(alignment='center')
                            cell(cols='narrow')
                              v-caption(size='5') {{ _('Show left tickets') }}
                            cell(cols='narrow')
                              v-tag(:type='form.is_left_tickets ? "success" : "brand-6"') {{ form.is_left_tickets ? _('show tickets') : _('hide tickets')}}
                              cell(cols='12 6-md')
          card-panel(offset='vertical' space='xl')
            card-panel(offset='vertical' indent='inside' space='xl')

              row( offset align='start')
                cell(cols='12')
                  v-caption(
                    size='5'
                    tag='h5'
                  ) {{ _('Ads settings')}} 
                cell(cols='12 6-md')
                  row(offset)
                    cell(cols='12' v-if='form.ads_image')
                      img(:src='form.ads_image')
                    cell(cols='12' v-if='form.ads_image')
                      v-button(
                        @click='form.ads_image = null',
                        :variant='["danger"]',
                        type='button'
                      )
                        template(v-slot:prepend='')
                          cell
                            v-button-element(
                              size='lg'
                              variant="danger"
                            )
                              v-caption(variant='bold')
                                icon(type='close'
                                    size='md')
                          cell(cols='auto')
                            v-button-element(size='lg')
                              v-caption(
                                size='5'
                                :variant='["bold", "uppercase"]'
                              ) {{ _('remove ads image') }}
                    cell(cols='12')
                      v-button(
                        variant='success'
                        border='whole'
                        @click='toggleAdsCropper'
                        hover='success-lite'
                      )
                        template(v-slot:prepend='')
                          cell
                            v-button-element(
                              size='lg'
                              variant="success-lite"
                            )
                              v-caption(variant='bold')
                                icon(type='upload'
                                    size='md')
                        cell(cols='auto')
                          v-button-element(size='lg')
                            v-caption(
                              size='5'
                              :variant='["bold", "uppercase"]'
                            ) {{ _('add ads image') }}
                cell(cols='12 6-md')
                  ui-input(
                    :placeholder="_('Ads url')"
                    :val='form.ads_url'
                    type='text'
                    name='ads_url'
                    validate='url'
                    v-model='form.ads_url'
                    size='lg'
                    border='whole'
                    bg='relief-3'
                  )
                  card(offset='top' )
            ui-toggler(type='checkbox'
                        v-model='form.show_info_message'
                        size='lg'
                        border='whole'
                        bg='relief-3'
                        :val='form.show_info_message'
                        name='is left tickets')
              template(v-slot:append='')
                row(alignment='center')
                  cell(cols='narrow')
                    v-caption(size='5') {{ _('Show info message') }}
                  cell(cols='narrow')
                    v-tag(:type='form.show_info_message ? "success" : "brand-6"') {{ form.show_info_message ? _('show info message') : _('don\'t show message')}}
                    cell(cols='12 6-md')
          card(offset='top' v-if="form.show_info_message")
            ui-toggler(type='checkbox'
                        v-model='form.show_info_on_ticket'
                        size='lg'
                        border='whole'
                        bg='relief-3'
                        :val='form.show_info_on_ticket'
                        name='is left tickets')
              template(v-slot:append='')
                row(alignment='center')
                  cell(cols='narrow')
                    v-caption(size='5') {{ _('Show info on the ticket? It will be displayed instead of ads image.') }}
                  cell(cols='narrow')
                    v-tag(:type='form.show_info_on_ticket ? "success" : "brand-6"') {{ form.show_info_on_ticket ? _('show info message on tickets') : _('don\'t show message on tickets')}}
                    cell(cols='12 6-md')
          card(
            v-if="form.show_info_message"
            offset='top'
          )
            ValidationProvider.control-input(
              :rules='`required`'
              v-slot='{errors}'
              name='info_message'
            )
              textarea.simple-textarea.control-input__element(
                :placeholder="_('Info message')"
                bg='relief-3'
                size='lg'
                type='textarea'
                name='info_message'
                v-model='form.info_message'
                validate='required'
                rows="3"
              )
              slot(name='error')
                +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}
          card-panel(offset='vertical' space='xl')
            card-panel(offset='vertical' indent='inside' space='xl')

              row( offset align='start')
                cell(cols='12')
                  v-caption(
                    size='5'
                    tag='h5'
                  ) {{ _('Analytic tags')}} 
                cell(cols='12')
          
                  card(
                    offset='top'
                  )
                    ValidationProvider.control-input(
                      v-slot='{errors}'
                      name='analytic_tags_head'
                    )
                      textarea.simple-textarea.control-input__element(
                        :placeholder="_('Analytic tags(insert in the end of head tag)')"
                        bg='relief-3'
                        size='lg'
                        type='textarea'
                        name='analytic_tags_head'
                        v-model='form.analytic_tags_head'
                        validate='required'
                        rows="3"
                      )
                      slot(name='error')
                        +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}
                  card(
                    offset='top'
                  )
                    ValidationProvider.control-input(
                      v-slot='{errors}'
                      name='analytic_tags_body'
                    )
                      textarea.simple-textarea.control-input__element(
                        :placeholder="_('Analytic tags(insert in the start of body tag)')"
                        bg='relief-3'
                        size='lg'
                        type='textarea'
                        name='analytic_tags_body'
                        v-model='form.analytic_tags_body'
                        validate='required'
                        rows="3"
                      )
                      slot(name='error')
                        +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}

</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters, mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import UiToggler from '@cabinet/components/forms/blocks/UiToggler'

import pathOr from 'ramda/src/pathOr'
import DropModal from '@cabinet/components/vendor/DropModal'

export default {
  props: {
    statuses: {
      required: true
    },
    timezones: {
      required: true
    },
    fees: {
      required: true
    },
    form: {
      required: true,
    },
    vendors: {
      required: false,
    },
    helpers: {
      required: false
    },
  },
  computed: {
    ...mapState('vendorsModule', [
      'countries',
      'states',
      'cities',
      'locations',
      'currencies',
    ]),
    ...mapGetters('showsModule', [
      'discount_methods',
      'payment_methods',
      'shipment_methods',
    ]),
    ...mapState('userModule', [
      'userData'
    ]),
    isProTariff() {
      return pathOr(false, ['tariff', 'tariff', 'type'], this.userData) === 'pro' ? true : false
    }
  },
  watch: {
    'form.is_locale': {
      handler: 'setLocaleTime',
    },
    'form.fee_descriptor': {
      handler(v) {
        console.log(v)
        this.feesData = v
      },
      deep: true
    },
    'form.start_at_date': {
      handler(val) {

        this.dateValid = val > this.form.finish_at_date
      },
      lazy: true
    },
    'form.finish_at_date': {
      handler(val) {
        this.dateValid = val < this.form.start_at_date
      },
      lazy: true
    },
    feesData: {
      deep: true,
      handler(val) {
        this.$emit('fee-changed', val)
      }
    },


    'helpers.country': {
      handler(val) {
        if (Number.isInteger(val)) {
          this.helpers.country = this.countries.find(c => c.id === val)
        }
      },
      immediate: true,
      deep: true
    },
    'helpers.subdivision': {
      async handler(val) {
        if (val.id || val) {
          await this.fetchStates(this.helpers.country.id || this.location.country)
        }
        if (Number.isInteger(val)) {
          this.helpers.subdivision = this.states.find(c => c.id === val)
        }
      },
      deep: true,
      immediate: true,
    },
    'helpers.city': {
      async handler(val) {
        if (val.id || val) {
          await this.fetchCities(this.helpers.subdivision.id || this.location.subdivision)
        }
        if (Number.isInteger(val)) {
          this.helpers.city = this.cities.find(c => c.id === val)
        }
      },
      deep: true,
      immediate: true,
    },
    'form.hall': {
      handler(val) {
        if (val && val.id) {
          this.fetchLocations_()
        }
      },
      deep: true,
      immediate: true,
    },
    'form.tz': {
      handler(val) {
        if (Number.isInteger(val)) {
          this.setTimezone()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    flatPickr,
    UiToggler
  },
  data() {
    return {
      dateValid: true,
      feesData: [{
        fee: {
          fee: {
            price: '',
            title: ''
          }
        },
        price: ''
      }],
      hidden: false,
      config: {
        // disableMobile: 'true',
        dateFormat: 'd-m-Y',
        altFormat: 'd-m-Y',
        // minDate: 'today',
        allowInput: true,
        altInput: true
      },
      timePicker: {
        // disableMobile: 'true',
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        altFormat: 'h:i K',
        altInput: true,
        allowInput: true,
        time_24hr: false,
      },
    }
  },
  methods: {
    toggleAdsCropper() {
      this.$modal.show(
        DropModal,
        
        {
          promise: this.adsPromise(),
          width: 500,
          height: 500
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
          width: 1000,

        }
      )
    },
    toggleCropper() {
      this.$modal.show(
        DropModal,
        
        {
          promise: this.promise(),
          width: 500,
          height: 500
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
          width: 1000,

        }
      )
    },
    adsPromise() {
      return arg => {
        return new Promise((resolve, reject) => {
          if (arg) {
            resolve(arg)
          } else {
            reject('error')
          }
        }).then(image => {
          this.$set(this.form, 'ads_image', image)
        })
      }
    },
    promise() {
      return arg => {
        return new Promise((resolve, reject) => {
          if (arg) {
            resolve(arg)
          } else {
            reject('error')
          }
        }).then(image => {
          this.$set(this.form, 'hall_image', image)
        })
      }
    },
    changeTime(value, model) {
      console.log(value)
      this.form[model] = value
    },
    // $nextTick(() => form.time == $event)
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        if (!this.form.sectors) {
          let data = (await this.getSectorsList({ id: this.form.hall.id }))
            .data
          data.forEach(s => (s.checked = true))
          this.form.sectors = data
        }
        return Promise.resolve({
          valid: true,
          msg: `${this._('You shall pass!!!')}`,
          observer: this.$refs.observer
        })


      }
      return Promise.reject({
        valid: false,
        msg: `${this._('You shall not pass!!!')}`,
        observer: this.$refs.observer
      })

    },
    addFee() {
      this.feesData.push({
        fee: {
          fee: {}
        },
        price: '',
      })
    },
    deleteFee(index) {
      this.feesData.splice(index, 1)
    },
    ...mapActions('vendorsModule', [
      'fetchCountries',
      'fetchStates',
      'fetchCities',
      'fetchLocations',
    ]),
    ...mapActions('hallModule', ['getSectorsList']),
    ...mapActions('showsModule', ['getShowOptions']),
    fetchLocations_() {
      // form.country.id
      // form.subdivision.id
      // form.city.id
      let { country, subdivision, city } = this.helpers
      this.fetchLocations({
        country,
        subdivision,
        city,
        status: 'published',
      })
    },
    resetFields(...args) {
      args.forEach(arg => (this.helpers[arg] = ''))
    },

    setTimezone() {
      if (Number.isInteger(this.form.tz)) {
        let item = this.timezones.find(tz => tz.id === this.form.tz_id)
        if (item) {
          this.form.tz_id = item
        }
      }
    },
    setupDate(target, val) {
      if (this.form[target].length > 0) {
        this.form[target] = val
      } else {
        return
      }
    },
    setLocaleTime(value) {
      if (value) {
        this.config.altFormat = 'd-m-Y'
        // this.config.dateFormat= 'd-m-Y'
        this.timePicker.altFormat = 'H:i'
        // this.timePicker.dateFormat = 'H:i'
        this.timePicker.time_24hr = true
      } else {
        this.config.altFormat = 'm/d/Y'
        // this.config.dateFormat= 'm/d/Y'
        this.timePicker.altFormat = 'h:i K'
        // this.timePicker.dateFormat = 'h:i K'
        this.timePicker.time_24hr = false
      }
    },
    changeLocale(value) {
      this.hidden = true
      this.setLocaleTime(value)
      setTimeout(() => {
        this.hidden = false

      })
      // $refs.timeat.fp.destroy()

    }
  },
  async mounted() {
    try {
    // this.$emit('loading-process', true)
      await this.fetchCountries()
      await this.setTimezone()
      // this.$emit('loading-process', false)
    } catch (err) {
      console.error(err)
      // this.$emit('loading-process', false)
    }
    this.changeLocale(this.form.is_locale)
  },
}
</script>
