<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    tag='form'
    v-slot='{invalid}'
    @submit.prevent='postProviderData'
    autocomplete='off'
  )
    row(offset)
      cell(cols='12')
        slot(name='hint')

      cell(cols='12')
        ui-input(
          :placeholder="_('Prefix *')"
          bg='relief-3'
          size='lg'
          border='whole'
          type='search'
          name='slug'
          v-model='provider.slug'
          :validate='{required: true, alpha: true, regex: /[A-Za-z]/}'
        )

      cell(cols='12')
        card-panel()
          card
            card-panel(
              :variant='["bordered", "border"]' 
              offset='full'
            )
              v-button(
                v-copypaste='{initial: _("copy"), clicked: _("copied")}'
                :variant='["copy", "clear"]'
                justification='between'
                alignment='center'

              )
                template()
                  cell()
                    row(offset alingment='center')
                      cell()
                        v-caption(size='5' variant='bold') {{ _('sign in for customers:') }}
                      cell
                        v-caption(size='5') {{ url + '/' + (provider.slug || "") }}
                        input.form__input(
                          readonly 
                          :value='url + "/" + (provider.slug || "")'
                          style='position: absolute; top: -1000vh'
                        )
                template(v-slot:append='')
                  cell()
                    icon(type='copy')
              row(offset)
                cell(cols='narrow')
                cell(cols='narrow')

      cell(cols='12 6-sm 3-md 6-lg 3-2xl')
        ui-input(
          :placeholder="_('website URL *')"
          bg='relief-3'
          size='lg'
          border='whole'
          type='search'
          name='link'
          v-model='provider.link'
          :validate='{url: true, required: true}'
        )
      cell(cols='12 6-sm 4-md 6-lg 4-2xl')
        row(offset)
          
          cell(cols='12')
            validation-provider(
              name='logo'
              ref='refProvider'
              v-slot='{errors}'
            )

              v-button(
                variant='success'
                border='whole'
                hover='success-lite'
                tag='label'
                @click="$refs.image.click()"
              )
                template(v-slot:prepend='')
                  cell
                    v-button-element(
                      size='lg'
                      variant="success-lite"
                    )
                      v-caption(variant='bold')
                        icon(type='upload'
                            size='md')
                cell(cols='auto')
                  v-button-element(size='lg') 
                    v-caption(
                      size='6'
                      :variant='["bold", "uppercase"]'
                    ) {{ provider.logo ? _('change logo') : _('add logo') }}
              input(
                type='file'
                style='display:none'
                accept='image/x-png,image/gif,image/jpeg'
                ref='image'
                name='file'
                @change='getFile($event.target.files);'
              )
              +b.control-hint.--styling_default.--variant_error(v-if='errors.length') {{ errors[0] }}
          cell(cols='12')
            +b.remove-wrapper
              +e.trigger(v-if="provider.logo" @click="deleteLogo" title="Delete")
                icon(type='close')
              +e.content
                img(:src='provider.logo' :key='provider.logo')

      cell(cols='12 6-sm 2-md 6-lg 2-2xl')
        row(offset)
          cell(cols='12')
            v-button(
              variant='success'
              border='whole'
              type='submit'
            )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    size='6'
                    :variant='["bold", "uppercase"]'
                  ) {{ _('save') }}
            
</template>

<script>
/* eslint-disable no-unused-vars */
import { 
  ref, 
  onMounted,
  toRefs,
  computed
} from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import { fileLoader } from '@cabinet/util'
import PSS from '@cabinet/services/api/provider-settings'

import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'

import isNil from 'ramda/src/isNil'

export default {
  components: {
    SecurityAccordion,
  },
  emit: ['legal', 'finish'],
  props: {
    initialProvider: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {

    const provider = ref(props.initialProvider)

    const logo = ref(null)
    const image = ref(null)

    const url = window.location.origin

    const isHasLogo = computed(() => {
      return !isNil(provider.value.logo)
    })

    const postProviderData = async () => {
      context.refs.refProvider.syncValue(provider.value.logo)
      const isValid = await context.refs.observer.validate()
      console.log('isValid', isValid);
      if (isValid) {
        let prov = Object.assign({}, provider.value)

        if (logo.value === prov.logo) {
          delete prov.logo
        }

        const action = () => {
          return !prov.id ? PSS.postProviderData(prov) : PSS.patchProviderData(prov)
        }
        try {
          const res = await useResponse(action(), context.root, context.refs.observer)
          context.emit('legal', true)
          context.emit('finish')

        } catch (error) {
          console.error(error)
        }
      }
    }
    const fetchProviderData = async () => {
      try {
        const res = await useResponse(PSS.fetchProviderData(), context.root, context.refs.observer)
        if (res.data) {
          console.log(res.data)
          provider.value = res.data
          logo.value = res.data.logo
          if (!("link" in res.data) || res.data.slug === "" || res.data.link === "") {
            context.emit('legal', false)
          } else {
            context.emit('legal', true)
          }
        } else {
          context.emit('legal', false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const setImage = (file) => {
      provider.value.logo = file
    }

    const getFile = (files) => {
      context.refs.observer.reset()
      fileLoader(files)(setImage)
    }

    const deleteLogo = () => {
      setImage('')
    }


    const promise = () => {
      return arg => {
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject('error')
        }).then(avatar => {
          provider.value.logo = avatar
        })
      }
    }

    return {
      provider,
      url,
      logo,
      getFile,
      postProviderData,
      deleteLogo,
      isHasLogo,
      image,
    }
  }
}
</script>