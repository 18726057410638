<template lang='pug'>
  include /mixins
  loading-wrapper(:loading='loading')
    template(v-slot:loading-title='')
      v-caption(
        variant='bold'
        size='6'
        color='default'
      ) {{ _('loading') }}
    card
      card-panel.offset_top_10xl(:offset='["horizontal", "top"]')
        row(appearance='spaced' space='md' alignment='center')
          cell(cols='narrow')
            v-button(
              tag='router-link'
              :to='"/account/events/"+$route.params.id+"/all-shows/"'
              :variant='["clear", "narrow"]'
              alignment='center'
            )
              template(v-slot:prepend='')
                cell
                  v-button-element()
                    icon(
                      type='left-arrow'
                      color='default'
                      size='xs'
                    )
              cell(cols='auto')
                v-button-element
                  v-caption(
                    variant='bold'
                    size='6'
                    color='default'
                  ) {{ _('ag back') }}
          cell(cols='auto' v-if='form.title')
            v-caption(size='5' variant='bold') {{ form.title }}
      card-panel(offset='full')
        +b.FORM.form
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab' :active='0')
                template(v-slot='{ activeItem, activate }')
                  +b.tab-list
                    +e.body
                      +e.toggle--variant_default.tab-list__item(
                        @click='isDisabled ? () => null : activateLocaly(index, true)'
                        v-for='(item, index) in stepsList'
                      )
                        v-caption(
                          :style='item.disabled ? "pointer-events:none;opacity:.5;" : ""'
                          :variant="[activeItem === index ? 'bold' : 'secondary', 'uppercase']"
                        ) {{ item.name }}

          card(variant='section' stacked='top')
            card-panel(:offset='["bottom"]')
              row(offset)
                cell(cols='12')
                  card(:offset='["top", "bottom"]' :variant='["offset"]')
                    card-panel(offset='full')
                      //- transition(:name='transitionName' mode='out-in')
                      component(
                        v-if='activeComponent'
                        ref='activeStep'
                        :is='activeComponent'
                        :form='form'
                        :vendors='vendors'
                        :key='activeStep'
                        :index='activeStep'
                        :addFee='addFee'
                        :fees='fees'
                        :removeFee='removeFee'
                        :timezones='timezones'
                        :statuses='statuses'
                        :helpers='helpers'
                        @validation='deactivateButton($event)'
                        @input='setSectors'
                        @loading='loading = $event'
                        @set-invalid='setInvalid'
                        @fee-changed='form.fee_descriptor = $event'
                      ).child-view
            card-panel(:offset='["full"]')
              row(
                offset
                justification='between'
              )
                cell(cols='narrow 2-xl' v-if='permissions')
                  v-button(
                    @click='toggleHold'
                    :variant='form.is_hold ? "warning" : "success"'
                    type='button'
                    :title='_("hold tooltip")'
                  )
                    cell(justification='center')
                      v-button-element(size='lg')
                        v-caption(
                          size='6'
                          :variant='["bold", "uppercase"]'
                        ) {{ form.is_hold ? _('unhold') : _('hold') }}

                cell(cols='narrow 10-xl 10-2xl')
                  row(offset justification='end' key='row')
                    cell(cols='narrow 2-2xl' v-if='activeStep' key='1')
                      v-button(
                        @click='activateLocaly(activeStep, "decrease")'
                        type='button'
                        :disabled='isDisabled'
                      )
                        cell(justification='center')
                          v-button-element(size='lg')
                            v-caption(size='6' :variant='["bold", "uppercase"]') {{ _('prev step') }}


                    cell(cols='narrow 2-2xl' key='2')
                      v-button(
                        @click='save'
                        variant='success'
                        type='button'
                        :disabled='isDisabled'
                      )
                        cell(justification='center')
                          v-button-element(size='lg')
                            v-caption(size='6' :variant='["bold", "uppercase"]') {{ _('save') }}

                    cell(cols='narrow 2-2xl' v-if='isNotLast' key='3')
                      v-button(
                        @click='activateLocaly(activeStep, "increase")'
                        type='button'
                        :disabled='isDisabled'
                      )
                        cell(justification='center')
                          v-button-element(size='lg')
                            v-caption(size='6' :variant='["bold", "uppercase"]') {{ _('next step') }}

</template>

<script>

import moment from 'moment'
window.moment = moment
const transform = date => {
  let a = date.split(/[^0-9]/)
  return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5])
}

const properTime = time => {
  let minutes = time.getMinutes()
  return [time.getHours(), minutes < 10 ? `0${minutes}` : minutes].join(':')
}

// const toUTC = (date, time) => {
//   return new Date(date).toLocaleString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
// }

// const getDateHours = (date) => date.getHours()

Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 0, 1)
  var jul = new Date(this.getFullYear(), 6, 1)
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset()
}


// const substract = (hours, tz, offset) => hours - tz - offset


function setTime(date_at, tz, check = false) {
  date_at = new Date(transform(date_at))

  const offset = date_at.getTimezoneOffset()
  const hours = date_at.getHours()
  date_at.setHours(hours - offset / 60)
  const time = properTime(date_at)
  return { date: date_at, time }
}

import axios from 'axios'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'
import ShowsService from '@cabinet/services/api/shows'
import StepFirst from '@cabinet/components/show/StepFirst'
import StepSecond from '@cabinet/components/show/StepSecond'
import StepThird from '@cabinet/components/show/StepThird'
import UiTabs from '@cabinet/components/vendor/UiTabs'
import FeesService from '@cabinet/services/api/fee'
import UiDelete from '@cabinet/components/modals/UiDelete'

export default {
  props: {
    vendors: {
      required: true,
    },
  },
  watch: {
    activeStep(nval, oval) {
      this.transitionName = nval < oval ? 'slide-left' : 'slide-right'
    },
    'form.finish_at_date': {
      handler(nVal) {
        if (nVal) {
          this.checkStatus(nVal)
        }
      }
    }
  },
  components: {
    StepFirst,
    StepSecond,
    StepThird,
    UiTabs,
  },
  computed: {
    ...mapState('userModule', ['prefix', 'userData']),
    permissions() {
      return this.userData.permissions.general_admission
    },
    isNotLast() {
      return this.activeStep < this.steps.length - 1
    },
    activeComponent() {
      return this.steps[this.activeStep]
    },
    ...mapGetters('showsModule', [
      'statuses'
    ]),
  },
  data() {
    return {
      fees: [],
      isDisabled: false,
      timezones: [],
      loading: false,
      interval: null,
      active: false,
      hash: 0,
      transitionName: 'slide-left',
      stepsList: [
        {
          name: this._('Step 1'),
          disabled: false,
        },
        {
          name: this._('Step 2'),
          disabled: true,
        },
        {
          name: this._('Step 3'),
          disabled: true,
        },
      ],
      steps: ['StepFirst', 'StepSecond', 'StepThird'],
      activeStep: 0,
      initialFormDescriptor: [],
      helpers: {
        country: '',
        subdivision: '',
        city: '',
      },
      hall_image: '',

      form: {
        title: '',
        time: '',
        editable: true,
        age_limit: null,
        date: '',
        locale: false,
        duration: '',
        is_locale: false,
        date_at_display: '',
        time_at_display: '',
        start_at_time: '',
        start_at_date: '',
        finish_at_time: '',
        finish_at_date: '',
        is_orphans: false,
        is_left_tickets: false,
        show_info_message: false,
        show_info_on_ticket: false,
        analytic_tags_head: '',
        analytic_tags_body: '',
        hall: '',
        payment_methods: [],
        notice: '',
        shipment_methods: [],
        is_tax: false,
        currency: '',
        fee_descriptor: [
          // {
          //   price: '',
          //   fee: {
          //     fee: {
          //       title: '',
          //       price: ''
          //     }
          //   },
          // },
        ],
        // hall block
        currency: {},
        sectors: [],
        tz: '',
        tz_id: '',
        price_descriptor: [
          {
            ga: false,
            title: '',
            price: '',
            color: '',
            sectors: [],
            seats: [],
            info: [
              {
                sector: {},
                row: {},
                seats: [],
                seats_from: {},
                seats_to: {},
              },
            ],
          },
        ],
        status: '',
        ads_url: '',
        ads_image: '',
      },
    }
  },
  methods: {
    setInvalid(v) {
      this.isDisabled = v
    },
    addFee() {
      let fees = this.form.fee_descriptor
      fees.push({
        fee: '',
        price: '',
      })
      this.$set(this.form, 'fee_descriptor', fees)
      // this.form.fee_descriptor
    },
    removeFee(index) {
      this.form.fee_descriptor.splice(index, 1)
    },
    deactivateButton({ valid }) {
      this.active = valid
    },
    ...mapActions('showsModule', ['createShow', 'editShow', 'getShowOptions']),
    ...mapMutations('showsModule', ['DISABLE_STATUS']),
    isStepValid(value) {
      this.stepsList[value].disabled = false
    },
    transformData(data, type) {
      data = JSON.parse(JSON.stringify(data))
      let item = {}
      if (data.tz_id) {
        data.tz = parseFloat(data.tz_id.offset)
        data.tz_offset = data.tz_id.utc ? data.tz_id.utc[0] : data.tz_id
      }


      if (data.hall_image === this.hall_image) {
        delete data.hall_image
      }
      Object.keys(data).forEach(k => {
        if (data[k] || typeof data[k] == 'boolean' || k === 'duration') {
          if (data[k] && data[k].id) {
            item[k] = data[k].id
          }
          if (data['hall']) {
            delete item['location']
          }
          if (k == 'currency') {
            item[k] = data[k].key
          }
          if (k == 'price_descriptor') {
            item[k] = [...data[k]]

            item[k].forEach((descriptor) => {
              descriptor.color = descriptor.color.id
              // descriptor.seats = descriptor.seats.map(s => s.id)
            })
          }
          if (
            k === 'notice' ||
            k === 'title' ||
            k === 'duration' ||
            k == 'shipment_methods' ||
            k == 'payment_methods' ||
            k == 'sectors' ||
            k == 'tz' ||
            k == 'tz_offset' ||
            k == 'is_tax' ||

            // k == 'date_at_display' ||
            // k == 'time_at_display' ||
            k == 'hall_image' ||
            k == 'is_orphans' ||
            k == 'is_locale' ||
            k == 'is_left_tickets' ||
            k == 'show_info_message' ||
            k == 'show_info_on_ticket' ||
            k == 'info_message' ||
            k == 'ads_url' ||
            k == 'analytic_tags_body' ||
            k == 'analytic_tags_head' ||
            k == 'ads_image'
          ) {
            item[k] = data[k]
          } else if (k == 'fee_descriptor') {
            item[k] = []
            data[k].forEach(key => {
              if (key && key.price && key.fee.id) {
                let _item = {
                  price: key.price,
                  fee: key.fee.id,
                  is_facility: key.is_facility
                }
                key.id ? _item.id = key.id : null
                item[k].push(_item)
              }
            })
          }

          if ((type === 'createShow' && this.activeStep !== 2) || data.price_descriptor.every(d => !d.title)) {
            delete item.price_descriptor
          }
        }
        if (k == 'age_limit') {
        console.log('AGE_LIMIT', data[k]);
        item[k] = data[k] || null
        console.log('AGE_LIMIT', item[k]);
        }
      })
      // let currentFormatParseAtDisplay = 'DD-MM-YYYY HH:mm'
      if (!data.is_locale) {
        item.date_at_display = moment(data.date_at_display, 'DD-MM-YYYY').format('MM/DD/YYYY')
        item.time_at_display = moment(data.time_at_display, 'HH:mm A').format('LT')
        // currentFormatParseAtDisplay = 'MM/DD/YYYY h:mm A'
      } else {
        item.date_at_display = moment(data.date_at_display, 'DD-MM-YYYY').format('DD-MM-YYYY')
        item.time_at_display = data.time_at_display
      }


      let currentFormatParse = 'DD-MM-YYYY HH:mm'
      let currentFormatTo = 'YYYY-MM-DD HH:mm'
      const startedFormatedData = moment(`${data.start_at_date} ${data.start_at_time}`, currentFormatParse).format(currentFormatTo)
      const finishFormatedData = moment(`${data.finish_at_date} ${data.finish_at_time}`, currentFormatParse).format(currentFormatTo)
      // Dima
      // const startAtTime = moment(`${data.start_at_time}`, [timeFormatParse]).format(timeFormatParseTo)
      // const finishAtTime = moment(`${data.finish_at_time}`, [timeFormatParse]).format(timeFormatParseTo)

      // const startAtDate = moment(`${data.start_at_date}`, 'MM-DD-YYYY').format('YYYY-MM-DD')
      // const finishAtDate = moment(`${data.finish_at_date}`, 'MM-DD-YYYY').format('YYYY-MM-DD')
      // Dima
      // Dima
      // item.start_at = moment(`${startAtDate} ${startAtTime}`)/* .utcOffset((data.tz * 60)) */.format()
      // item.finish_at = moment(`${finishAtDate} ${finishAtTime}`)/* .utcOffset((data.tz * 60)) */.format()
      // Dima


      // Vlad
      item.start_at = moment(`${startedFormatedData}`).format()
      item.finish_at = moment(`${finishFormatedData}`).format()
      // Vlad
      item.event = parseInt(this.$route.params.id)
      item.status = data.status.id
      console.log('item', item)
      return item
    },
    async save(redirect = true) {
      let action = this.$route.name === 'edit-show' ? 'editShow' : 'createShow'
      let { showId } =
        this.$route.name === 'edit-show' ? this.$route.params : ''
      let promiseResult
      try {
        promiseResult = await this.$refs.activeStep.save()
      } catch (error) {
        console.error(error)
        return error
      }


      try {
        this.loading = true
        const res = await useResponse(this[action]({ data: this.transformData(this.form, action), id: showId }), this, promiseResult.observer)
        this.loading = false
        if (redirect && res.data) {
          return Promise.resolve(this.$router.push(`${this.prefix}/account/events/${this.$route.params.id}/all-shows/?highlight=${res.data.id}&date=${res.data.finish_at}`))
        }
        return Promise.resolve(res.data.id)

      } catch (error) {
        promiseResult.observer.setErrors(error)
        this.loading = false
        return Promise.reject()
      }
    },
    setSectors(emittedValue) {
      let arr = []
      emittedValue.forEach(val => {
        val = Object.assign({}, val)
        delete val.disabled
        arr.push(val)
      })
      this.form.sectors = arr
    },
    checkStatus(value) {

      const now = moment()
      const finish = moment(value)
      const difference = finish.isBefore(now, 'day')

      if (difference) {
        this.DISABLE_STATUS({title: 'Published', isDisabled: true})
      } else {
        this.DISABLE_STATUS({title: 'Published', isDisabled: false})
      }
    },
    showModal() {
      this.$modal.show({
        template:
          `
          <div class="modal-wrapper">
            <div class="modal-wrapper__close btn btn--flex btn--center" @click="$emit(&quot;close&quot;)">
              <div class="btn__icon btn__icon--default icon-close"></div>
            </div>
            <div class='row row--column row--center'>
              <div class='row__el'>
                <h2>${this._('this show data IS NOT EDITABLE!')}</h2>
              </div>
            </div>
          </div>
          `,
        inheritAttrs: false

      }, {
      }, {
        width: 320,
        height: 'auto',
        adaptive: true,
        scrollable: true
      })
    },
    activateLocaly(index, type) {
      // if (!this.form.editable) {
      //   this.showModal()
      //   return
      // }
      // if (!this.active) return

      let hash = index => (window.location.hash = `step${index + 1}`)
      setTimeout(async () => {
        try {
          let result
          let id
          if (type) {
            result = await this.$refs.activeStep.save()
            id = await this.save(false)
          } else {
            result = {
              valid: true,
            }
          }

          if (!result.valid) {
            return
          }

          this.active = result.valid


          if (type === 'increase') {
            index++
          } else if (type === 'decrease') {
            index--
          }
          this.isStepValid(index)

          if (this.activeStep != index) {
            this.$router.push(`/account/events/${this.$route.params.id}/edit-show/${id ? id : this.$route.params.showId}/#step${this.activeStep}`)
          }
          this.activeStep = index
          if (index < this.steps.length && index >= 0) {
            setTimeout(() => {
              this.$nextTick(() => {
                hash(index)
                this.$refs.tab.activate(index)
              })
            })
          } else if (this.activeStep < 0) {
            this.activeStep = 0
            hash(0)
          } else if (this.activeStep >= this.steps.length) {
            this.activeStep = this.steps.length - 1
            hash(this.activeStep)
          }
        } catch (error) {
          console.error(error)
        }
      }, 1)
    },
    ...mapActions('showsModule', ['fetchShow']),
    async toggleHold() {
      const show = { id: this.form.id }
      const action = () => this.form.is_hold ? ShowsService.unhold(show) : ShowsService.hold(show)
      const res = await useResponse(action(), this, this.$refs.observer)

      this.form.is_hold = res.data.is_hold
      if (res.data.is_hold) {
        this.activateHoldTask()
      } else {
        clearInterval(this.interval)
      }
    },
    activateHoldTask() {
      ShowsService.hold({ id: this.form.id })
      const interval = 1000 * 4 * 60
      this.interval = setInterval(() => {
        ShowsService.hold({ id: this.form.id })
      }, interval)
    },
    openInfoModal(next) {
      const fn = condition => new Promise((resolve, reject) => {
        if (condition) {
          resolve(next())
        } else {
          reject()
        }
      })
      this.$modal.show(
        UiDelete,
        
        {
          customText: this._('Your show is on hold!\n If you leave it and left unhold it will be unhold automatically after 5 minutes!\n Do you want to leave it?'),
          fn: fn,
          itemID: true,
        },
        {
          adaptive: true,
          maxWidth: 330,
          height: 'auto',
          scrollable: true,
        }
      )
    },
    async getFees() {
      try {
        let data = (await FeesService.fetch()).data
        this.fees = data.data
        // this.fees = data.data
      } catch (err) {
      }
    },
    async setStatus() {
      try {
        let item = this.statuses.find(i => i.id == this.form.status || i.id === this.form.status.id)
        this.form.status = item
      } catch (error) {
        console.error(error)
      }
    },
  },

  async created() {
    await this.getShowOptions()
    await this.getFees()
    try {
      await axios.get('/static/timezones.json').then(res => {
        this.timezones = res.data.sort((a, b) => b.offset - a.offset)
      })
    } catch (err) {
      console.error(err)
    }
    if (this.$route.name === 'edit-show') {
      this.loading = true
      let { showId } = this.$route.params
      let data
      try {

        data = (await this.fetchShow({ id: showId, query: '?additional_hall=1&additional_info=1' }))
      } catch (err) {
        this.loading = false
        this.$router.push('/account/account-settings/')
        return
      }

      if (!data.is_owner) {
        this.$router.push('/account/account-settings/')
        return
      }
      this.hall_image = data.hall_image
      this.form = data

      if (this.form.is_hold) {
        this.activateHoldTask()
      }

      if (!this.form.price_descriptor.length) {
        this.form.price_descriptor = [
          {
            ga: false,
            title: '',
            price: '',
            color: '',
            seats: [],
            info: [
              {
                sector: {},
                row: {},
                seats: [],
                seats_from: {},
                seats_to: {},
              },
            ],
          },
        ]
      }

      if (!this.form.shipment_methods) {
        this.form.shipment_methods = []
      }

      if (!this.form.fee_descriptor) {
        this.form.fee_descriptor = []
      } else {
        this.form.fee_descriptor = this.form.fee_descriptor.map(f => {
          if (Number.isInteger(f.fee)) {
            let item = this.fees.find(i => i.id == f.fee)
            f.fee = item
            return f
          }
        })
      }



      if (!this.form.payment_methods) {
        this.form.payment_methods = []
      }

      let { country, city, subdivision } = data.hall ? data.hall.location : data.location

      this.helpers.country = country
      this.helpers.city = city
      this.helpers.subdivision = subdivision


      this.form.sectors = data.sectors

      let tz = data.tz

      if (data.date_at) {
        let { date, time } = setTime(data.date_at, tz)
        this.form.date = date
        this.form.time = time
      }

      if (data.start_at) {
        // const newDate = moment(data.start_at).utcOffset(tz).format()

        let { date, time } = setTime((data.start_at), tz)
        this.form.start_at_date = date
        this.form.start_at_time = time
      }

      if (data.finish_at) {
        // const newDate = moment(data.finish_at).utcOffset(tz).format()
        let { date, time } = setTime(data.finish_at, tz)
        this.form.finish_at_date = date
        this.form.finish_at_time = time
      }
      if (!data.is_locale) {
        if (data.date_at_display) {
          this.form.date_at_display = moment(data.date_at_display, 'MM/DD/YYYY').format('DD-MM-YYYY')
        }
        if (data.time_at_display) {
          this.form.time_at_display = moment(data.time_at_display, 'h:mm A').format("HH:mm")
        }
      }

      try {

        await this.setStatus()
      } catch (error) {
        console.error(error)
      }


      // REMOVE IT ON PRODUCTION
      let hash = window.location.hash
      setTimeout(() => {
        hash = hash.split('#step')[1] - 1 || this.activeStep
        this.activeStep = hash
        this.activateLocaly(hash)
      }, 100)
      // REMOVE IT ON PRODUCTION
      this.loading = false

    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  beforeRouteLeave(to, from, next) {
    if (this.form.is_hold) {
      this.openInfoModal(next)
    } else {
      next()
    }
  }
}

</script>
