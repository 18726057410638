import Api, {editedSlug as provider} from '@cabinet/services'

export default {
  fetchBilling() {
    return Api().get('/settings/billing/receive/', {
      
    })
  },

  fetchDelivery() {
    return Api().get('/settings/delivery/receive/', {
      
    })
  },

  postBilling(data) {
    return Api().post('/settings/billing/update/', data,  {
      
    })
  },

  fetchProvidersData() {
    return Api().get('/settings/provider/list/')
  },

  fetchProviderData(id) {
    return Api().get(`/settings/provider/${id}/receive/`)
  },

  patchProviderData(data) {
    return Api().patch(`/settings/provider/${data.id}/update/`, data)
  },

  postProviderData(data) {
    return Api().post('/settings/provider/create/', data)
  },
  makeTestPayment() {
    return Api().post('/settings/billing/test/',  {
      
    })
  },
  requestHall(data) {
    return Api().post('/places/hall/request/create/',  data)
  }
}